<template>
  <div class="content" v-wechat-title="$route.meta.title=curriculumInfo.curriculumTitle">
    <!-- <van-nav-bar
      :title="curriculumInfo.curriculumTitle"
      left-arrow
      @click-left="onClickLeft"
      :border="false"
    /> -->
    <van-image
      :src="`http://114.116.236.37:9001/${curriculumInfo.curriculumImage}`"
      height="170px"
      fit="cover"
    />
    <div class="content-item">
      <span class="item-browse">{{ curriculumInfo.curriculumViews }}已浏览</span>
      <span class="van-ellipsis item-title">{{ curriculumInfo.curriculumTitle }}</span>
    </div>
    <div class="boeder-item"></div>
    <span class="teach-title">授课老师</span>
    <van-row class="teach-content">
      <van-col span="8">
        <van-image round :src="require(`@/assets/image/an.png`)" class="teach-portrait" />
      </van-col>
      <van-col span="16" class="teach-item">
        <p class="teach-name">{{ curriculumInfo.curriculumAuthor }}</p>
        <span class="teach-level">老师</span>
      </van-col>
    </van-row>
    <span class="detail-title">课程详情</span>
    <div class="detail-content" v-html="curriculumInfo.curriculumMemo"></div>
    <!-- <div class="detail-content">
      <div class="item-left">
        <van-image :src="require(`@/assets/image/people11.jpg`)" class="left-img"/>
      </div>
      <div class="item-right">
        <div class="right-title">PPT从入门到精通 移动学习版课程</div>
        <div class="right-title-item">
          <span class="right-spot"></span>
          第一篇"word办公应用篇"主要介绍office的安装与设置</div>
        <div class="right-title-item1">
          <span class="right-spot-item1"></span>
          Word的基本操作，使用图和表格美化word文档，以及文档的排版等</div>
      </div>
    </div>-->
    <!-- <van-image :src="require(`@/assets/image/11.png`)" class="p16"/> -->
    <div class="footer-back"></div>
    <div class="footer-btn p16">
      <van-button round block color="#FE8711" @click="showstu(curriculumInfo)" class="btn">开始学习</van-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET } from '@/common/request';
const api = {
  querySrCurriculumInfoById: '/xkcshowApi/show/querySrCurriculumInfoById.htm',
  addSrRecord: '/xkcshowApi/show/addSrRecord.htm',
  getAuth: '/wechat/auth'
};
export default {
  data() {
    return {
      curriculumInfo: {}
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ])
  },
  mounted() {
    this.getCurriculumInfo(this.$route.params.id)
    // console.log(this.$route.params.id)
  },
  methods: {
    getCurriculumInfo(id) {
      GET(api.querySrCurriculumInfoById, { id })
        .then((res) => {
          if (res.code === 200) {
            this.curriculumInfo = res.data
            // this.advertisementList = res.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    onClickLeft() {
      window.history.go(-1);
    },
    showstu(val) {
      // const path = encodeURIComponent('https://baidu.com')

      // GET(api.getAuth, {
      //   returnUrl: 'suoer',
      //   authCallbackUrl: 'https://baidu.com'
      // })
      //   .then((res) => {
      //     location.replace(
      //       res.data
      //     )
      //   })
      //   .catch(error => {
      //     console.log(error)
      //   })
        
      // const path = encodeURIComponent('https://baidu.com')
      // // const path = encodeURIComponent('http://192.168.100.13:8080')
      // location.replace(
      //   `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe2dad8a3e6350e40&redirect_uri=${path}&response_type=code&scope=snsapi_base&state=&connect_redirect=1#wechat_redirect`
      // )

      // if (this.token === '') {
      // this.$store.dispatch('user/login').then((res) => {
      // this.$router.push({ path: this.redirect || '/' })
      // this.loading = false

      // const path = encodeURIComponent('https://baidu.com')
      // // const path = encodeURIComponent('http://192.168.100.13:8080')
      // location.replace(
      //   `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe2dad8a3e6350e40&redirect_uri=${path}&response_type=code&scope=snsapi_userinfo&state=&connect_redirect=1#wechat_redirect`
      // )

      // }).catch(() => {
      //   // this.loading = false
      // })
      // }

      // this.$router.push({
      //   path: '/videodetils',
      //   query: {
      //     code: '091IsSFa1grhfC0y9RHa1K6XLI1IsSFD',
      //     state: '535df749254b5b843a3f992cf6e91025'
      //   }
      // })
      // this.$router.push({name:'videodetils'})
//       console.log(val)
// debugger
      this.$router.push({
        path: '/videodetils',
        query: {
          id:val.id
        }
      })
    },

    // postAddSrRecord(data) {
    //   POST(api.addSrRecord, { openId: data.openid, sex: data.sex, wxAvatar: data.headimgurl, wxNickname: data.nickname })
    //     .then((res) => {
    //       console.log(res.data)
    //       // if (res.code === 200) {
    //       //   this.curriculumInfo = res.data
    //       //   // this.advertisementList = res.data
    //       // }
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__title {
  font-weight: 700;
  color: #333333;
}
/deep/ .van-icon-arrow-left:before {
  color: #6a6a6a;
}
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
}
.content-item {
  height: 30px;
  display: flex;
  line-height: 30px;
  justify-content: space-between;
  margin: 15px;
  .item-browse {
    width: 25%;
    height: 24px;
    line-height: 24px;
    border-radius: 15px;
    background: #9f5cea;
    font-size: 12px;
    color: #fff;
    margin-top: 2px;
  }
  .item-title {
    width: 60%;
    color: #333333;
    font-size: 18px;
    font-weight: 600;
  }
}
.boeder-item {
  height: 10px;
  background: #f7f7f7;
}
.teach-title {
  display: flex;
  justify-content: start;
  margin: 20px;
  font-size: 18px;
  color: #333333;
  font-weight: 700;
}
.teach-content {
  width: 166px;
  height: 60px;
  background: #f7fafc;
  margin-left: 20px;
  border-radius: 10px;
  .teach-portrait {
    width: 50px;
    height: 50px;
    margin: 5px 10px;
  }
}
.teach-item {
  // display: flex;
  // height: 50%;
  // width: 50%;
  text-align: left;
  padding-left: 15px;
  .teach-name {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
    margin-top: 15px;
  }
  .teach-level {
    font-size: 10px;
    color: #666666;
    // margin-top: 15px;
    // margin: 36px 33px 0 0;
  }
}
.detail-title {
  display: flex;
  justify-content: start;
  margin: 25px 0 0 20px;
  font-size: 18px;
  color: #333333;
  font-weight: 700;
}

.detail-content {
  margin: 15px auto;
  text-align: start;
  width: 96%;
  /deep/ img{
    width: 100%;
  }
} 


// .detail-content {
//   display: flex;
//   justify-content: space-around;
//   overflow: hidden;
//   height: 161px;
//   margin: 16px;
//   background: #F7F7F7;
//   border-radius: 10px;
//   // margin-bottom: 50px;
//     .item-left {
//       width: 50%;
//       height: 100%;
//       margin-top: 8px;
//       // margin-right: 10px;
//       .left-img {
//         width: 90%;
//         height: 90%;
//       }
//     }
//     .item-right {
//       // width: 100%;
//       font-size: 10px;
//       color: #8989A6;
//       margin: 20px 20px 0 0;
//     }
//     .right-title {
//       font-size: 14px;
//       color: #333333;
//       font-weight: 600;
//     }
//     .right-title-item {
//       display: flex;
//       margin-top: 15px;
//       text-align: justify;
//       line-height: 20px;
//       .right-spot {
//         width: 6px;
//         height: 5px;
//         background-color: #FE6D03;
//         border-radius: 50%;
//         margin: 7px 5px 0 5px;
//       }
//     }
//     .right-title-item1 {
//       display: flex;
//       margin-top: 10px;
//       text-align: justify;
//       line-height: 20px;
//       .right-spot-item1 {
//         width: 8px;
//         height: 5px;
//         background-color: #23B8FF;
//         border-radius: 50%;
//         margin: 7px 5px 0 5px;
//       }
//     }
//   }
.footer-back {
  width: 100%;
  height: 100px;
}
.footer-btn {
  width: 100%;
  height: 70px;
  line-height: 70px;
  background-color: #fff;
  position: fixed;
  // top: 90%;
  bottom: 0;
  .btn {
    height: 50px;
    line-height: 50px;
    align-items: center;
    margin-top: 9px;
  }
  .van-button--round {
    border-radius: 10px;
  }
}
</style>